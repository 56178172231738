/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { isInProduction } from '../utils/isInProduction';

function SEO({ description, title, faq, imageURL, customURL }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const isBrowser = typeof window !== 'undefined';
  const currentUrl = isBrowser ? window.location.href : null;

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const url = customURL || currentUrl;

  return (
    <Helmet titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}>
      <meta httpEquiv="content-language" content="de" />
      <link rel="canonical" href={url} />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={metaDescription} />
      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      {imageURL && <meta property="og:image" content={imageURL} />}
      {/* Twitter */}
      <meta
        property="twitter:card"
        content={`summary${imageURL ? '_large_image' : ''}`}
      />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:author" content={site.siteMetadata?.author || ''} />
      <meta property="twitter:description" content={metaDescription} />
      {imageURL && <meta property="twitter:image" content={imageURL} />}

      {isInProduction && (
        <script
          src="https://plausible.io/js/plausible.outbound-links.js"
          async
          defer
          data-domain="about.convela.cloud"
        />
      )}

      {/* FAQ */}
      {faq.length && (
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              ${faq.map(
                (item) =>
                  `{
                    "@type": "Question",
                    "name": "${item.question}",
                    "acceptedAnswer": {
                      "@type": "Answer",
                      "text": "${item.answer}"
                    }
                  }`
              )}
            ]
          }`}
        </script>
      )}
    </Helmet>
  );
}

SEO.defaultProps = {
  description: '',
  faq: [],
  imageURL: null,
  customURL: null
};

SEO.propTypes = {
  customURL: PropTypes.string,
  description: PropTypes.string,
  faq: PropTypes.array,
  imageURL: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default SEO;
